import {Actor} from './activity-log.class';
import moment from 'moment';

export class CustomerNote {
    constructor(public id: string         = null,
                public customerId: string = null,
                public actor: Actor       = new Actor(),
                public content: string    = null,
                public createdAt: string  = null,
                public updatedAt: string  = null) {
    }

    getElapsedTime() {
        return moment(this.createdAt).utc(true).fromNow();
    }
}

export function mapCustomerNote(item: any): CustomerNote {
    return new CustomerNote(
        item.id,
        item.customerId,
        item.actor,
        item.content,
        item.createdAt,
        item.updatedAt
    );
}

