import {VenueSectionSeat} from './venue-section-seat.class';
import {Customer, mapCustomer} from './customer.class';
import {mapOrder, Order} from './order.class';
import {mapTicketType, TicketType} from './ticket-type.class';
import {Event, mapEvent} from './event.class';

export class EventTicket {
    constructor(public id: string,
                public eventId: string,
                public ticketTypeId: string,
                public venueId: string | null,
                public venueSectionId: string | null,
                public venueSectionSeatId: string | null,
                public venueSectionGroupId: string | null,
                public orderItemId: string,
                public barcode: string,
                public isRenewed: boolean,
                public status: string,
                public name: string,
                public seat: VenueSectionSeat,
                public customerId: string,
                public personalisationCustomerId: string,
                public venueSectionAccessIds: string[],
                public subscriptionId: string | null,
                public note: string | null,
                public isResellAllowed?: boolean,
                public isExternalBarcode?: boolean,
                public customer?: Customer,
                public personalisationCustomer?: Customer,
                public guestManager?: any,
                public scanStatus?: any,
                public order?: Order,
                public eventName?: string,
                public ticketType?: TicketType,
                public event?: Event) {
    }
}

export function mapEventTicket(item: any): EventTicket {
    return new EventTicket(
        item.id,
        item.eventId,
        item.ticketTypeId,
        item.venueId,
        item.venueSectionId,
        item.venueSectionSeatId,
        item.venueSectionGroupId,
        item.orderItemId,
        item.barcode,
        item.isRenewed,
        item.status,
        item.name,
        item.seat,
        item.customerId,
        item.personalisationCustomerId,
        item.venueSectionAccessIds,
        item.subscriptionId,
        item.note,
        item.isResellAllowed,
        item.isExternalBarcode,
        item.customer ? mapCustomer(item.customer) : null,
        item.personalisationCustomer ? mapCustomer(item.personalisationCustomer) : null,
        item.guestManager,
        item.scanStatus,
        item.order ? mapOrder(item.order) : null,
        item.eventName,
        item.ticketType ? mapTicketType(item.ticketType) : null,
        item.event ? mapEvent(item.event) : null,
    );
}
