import {ModelBase} from './abstract/model-base';
import {Customer, mapCustomer} from './customer.class';

export class OrderDelivery extends ModelBase {
    public id: string;
    public orderId: string;
    public method: string;
    public email: string;
    public phoneNumber: string;
    public firstName: string;
    public lastName: string;
    public sendAt: string;
    public amount: number;
    public url?: string;
    public customer?: Customer;

    constructor() {
        super();
        super.setRoutes({
            post: 'v1.0/order/:orderId/delivery'
        });
    }

    mapModel(data: any) {
        const model = new OrderDelivery();

        model.id = data.id;
        model.orderId = data.orderId;
        model.method = data.method;
        model.email = data.email;
        model.phoneNumber = data.phoneNumber;
        model.firstName = data.firstName;
        model.lastName = data.lastName;
        model.sendAt = data.sendAt;
        model.amount = data.amount;
        model.url = data.url;
        model.customer = mapCustomer(data);

        return model;
    }

}

export function mapOrderDelivery(item: any): OrderDelivery {
    return (new OrderDelivery()).mapModel(item);
}
