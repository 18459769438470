import {EventTicket, mapEventTicket} from './event-ticket.class';
import {mapSubscription, Subscription} from './subscription.class';
import {mapProduct, Product} from './product';
import {VenueSectionSeat} from './venue-section-seat.class';
import {Customer} from './customer.class';
import {mapTicketType, TicketType} from './ticket-type.class';

export class OrderItem {
    isSelected = false;
    isDisabled = false;
    orderItemGroupId = null;

    public name: string;
    public groupId: string;
    public groupName: string;
    public groupDate: string;
    public priceName: string;
    public type: 'EVENT_TICKET' | 'SUBSCRIPTION' | 'PRODUCT';
    public status: string;
    public personalisationCustomer: Customer;
    public venueSectionSeat: VenueSectionSeat;

    constructor(public id: string,
                public orderId: string,
                public price: number,
                public vat: string,
                public currency: string,
                public ticket: EventTicket,
                public subscription: Subscription,
                public product: Product,
                public batchId: string,
                public hash: string,
                public downloadUrl?: string,
                public personalisationUrl?: string,
                public isPersonalised?: boolean,
                public isPersonalisationRequired?: boolean,
                public venueId?: string,
                public types?: TicketType[],
                public note?: string,
                public additionalCosts?: any[],
                public _loading?: boolean) {
    }

    public initialize(): void {
        this.setName();
        this.setGroupDetails();
        this.setPriceName();
        this.setType();
        this.setStatus();
        this.setPersonalisationCustomer();
        this.setVenueSectionSeat();
    }

    private setName(): void {
        this.name = this.ticket?.name || this.subscription?.name || this.product?.name || '-';
    }

    private setGroupDetails(): void {
        this.groupId = this.ticket?.eventId || this.subscription?.subscriptionTypeId || this.product?.productTypeId;
        this.groupName = this.ticket ? this.getTicketGroupName(this.ticket) : this.subscription?.subscriptionTypeName || this.product?.productTypeName;
        this.groupDate = this.ticket?.event?.startAt || this.subscription?.startAt || null;
    }

    private setPriceName(): void {
        this.priceName = this.ticket?.ticketType?.name || this.subscription?.subscriptionTypePrice?.name || this.product?.productTypePrice?.name || '-';
    }

    private setType(): void {
        if (this.ticket) {
            this.type = 'EVENT_TICKET';
        } else if (this.subscription) {
            this.type = 'SUBSCRIPTION';
        } else if (this.product) {
            this.type = 'PRODUCT';
        }
    }

    private setStatus(): void {
        this.status = this.ticket?.status || this.subscription?.status || this.product?.status;
    }

    private setPersonalisationCustomer(): void {
        if (this.ticket) {
            this.personalisationCustomer = this.ticket.personalisationCustomer;
        } else if (this.subscription) {
            this.personalisationCustomer = this.subscription.personalisationCustomer;
        } else if (this.product) {
            this.personalisationCustomer = null;
        }
    }

    private setVenueSectionSeat(): void {
        if (this.ticket) {
            this.venueSectionSeat = this.ticket.seat;
        } else if (this.subscription) {
            this.venueSectionSeat = this.subscription.seat;
        } else if (this.product) {
            this.venueSectionSeat = null;
        }
    }

    private getTicketGroupName(eventTicket: EventTicket): string {
        return eventTicket.event?.name;
    }

}

export function mapOrderItem(item: any): OrderItem {
    return new OrderItem(
        item.id,
        item.orderId,
        item.price,
        item.vat,
        item.currency,
        item.ticket ? mapEventTicket(item.ticket) : null,
        item.subscription ? mapSubscription(item.subscription) : null,
        item.product ? mapProduct(item.product) : null,
        item.batchId,
        item.hash,
        item.downloadUrl,
        item.personalisationUrl,
        item.isPersonalised,
        item.isPersonalisationRequired,
        item.venueId,
        item.types ? item.types.map(type => mapTicketType(type)) : [],
        item.note,
        item.additionalCosts
    );
}
