import {ModelBase} from './abstract/model-base';
import {Customer, mapCustomer} from './customer.class';
import {mapOrder, Order} from './order.class';
import {VenueSection} from './venue-section.class';
import {RequestItem} from './request-item.class';
import {Venue} from './venue.class';
import {groupBy} from '../../core/helpers/array-helper';

export class Request extends ModelBase {
    public id: string = null;
    public customerId: string = null;
    public orderId: string = null;
    public amount: number = null;
    public status: string = null;
    public createdAt: string = null;

    public customer: Customer | null = null;
    public order: Order | null = null;
    public requestItems: RequestItem[] | null = [];

    _moveVenueSection: VenueSection;
    _moveVenue: Venue;
    _requestItemGroups: any[] = [];

    constructor() {
        super();
        super.setRoutes({
            getMany: 'v1.0/request',
            get: 'v1.0/request/:id',
            patch: 'v1.0/request/:id'
        });
    }

    mapModel(data: any) {
        const request = new Request();
        request.id = data.id;
        request.customerId = data.customerId;
        request.orderId = data.orderId;
        request.amount = data.amount;
        request.status = data.status;
        request.createdAt = data.createdAt;

        request.customer = data.customer ? mapCustomer(data.customer) : null;
        request.order = data.order ? mapOrder(data.order) : null;
        request.requestItems = data.requestItems ? data.requestItems.map(requestItem => (new RequestItem()).mapModel(requestItem)) : [];

        request._requestItemGroups = this.getGroupedRequestItems(request.requestItems);

        return request;
    }

    private getGroupedRequestItems(requestItems: RequestItem[]): any[] {
        return Object.values(groupBy(requestItems, '_typeId')).map((group) => {
            const requestItem = group[0];
            return {
                name: requestItem.ticketType?.name || requestItem.subscriptionTypePrice?.name || requestItem.productTypePrice?.name,
                groupId: requestItem.event?.id || requestItem.subscriptionType?.id || requestItem.productType?.id,
                groupName: requestItem.event?.name || requestItem.subscriptionType?.name || requestItem.productType?.name,
                typeId: requestItem.ticketTypeId || requestItem.subscriptionTypePriceId || requestItem.productTypePriceId,
                venueSectionId: requestItem.venueSectionId,
                venueSectionName: requestItem.venueSection?.name || '',
                amount: (group as any).length
            };
        });
    }
}
