import {ProductType} from './product-type';
import {ProductTypePrice} from './product-type-price';
import {ModelBase} from './abstract/model-base';
import {Customer, mapCustomer} from './customer.class';

export class Product extends ModelBase {
    public id: string;
    public orderItemId: string;
    public productType: ProductType;
    public productTypeId: string;
    public productTypePrice: ProductTypePrice;
    public productTypePriceId: string;
    public customer: Customer;
    public order: any;
    public customerId: string;
    public status: string;
    public name: string;
    public note: string;
    public productTypeName: string;

    constructor() {
        super();

        super.setRoutes({
            getMany: 'v1.0/product'
        });
    }

    mapModel(data: any) {
        const model = new Product();

        model.id = data.id;
        model.orderItemId = data.orderItemId;
        model.productType = data.productType ? new ProductType().mapModel(data.productType) : null;
        model.productTypeId = data.productType ? data.productType.id : null;
        model.productTypePrice = data.productTypePrice ? new ProductTypePrice().mapModel(data.productTypePrice) : null;
        model.productTypePriceId = data.productTypePrice ? data.productTypePrice.id : null;
        model.customer = data.customer ? mapCustomer(data.customer) : null;
        model.order = data.order;
        model.customerId = data.customerId;
        model.status = data.status;
        model.name = data.name;
        model.note = data.note;
        model.productTypeName = data.productTypeName;

        return model;
    }
}

export function mapProduct(item: any): Product {
    return (new Product()).mapModel(item);
}
