import {OrderItemGroup} from './order-item-group.class';
import {Customer, mapCustomer} from './customer.class';
import moment from 'moment';
import {Payment} from './payment.class';
import {Consent} from './consent.class';
import {AdditionalCost, mapAdditionalCost} from './additional-cost.class';
import {ExternalData} from './external-data.class';
import {Request} from './request.class';
import {ModelBase} from './abstract/model-base';
import {mapOrderItem, OrderItem} from './order-item.class';
import {mapOrderDelivery, OrderDelivery} from './order-delivery.class';
import {ProductGroupType} from '../enums/order/product-group-type';
import {mapTicketType, TicketType} from './ticket-type.class';
import {TableRow} from './abstract/table-row';
import {Campaign, mapCampaign} from './campaign.class';

export class Order extends ModelBase implements TableRow {

    public id: string = null;
    public importId: string = null;
    public campaignId: string = null;
    public externalReference: string = null;
    public batchId: string = null;
    public status: string = null;
    public customerId: string = null;
    public payedAt: string = null;
    public canceledAt: string = null;
    public createdAt: string = null;
    public updatedAt: string = null;
    public totalPrice: number = null;
    public totalDiscountAmount: number = null;
    public discounts: any[] = [];
    public orderItemAmount = 0;
    public balance: number = null;
    public payments: Payment[] = [];
    public campaign: Campaign = null;
    public consents: Consent[] = [];
    public groupedOrderItems: OrderItemGroup[] = [];
    public additionalCosts: AdditionalCost[] = [];
    public externalData: ExternalData[] = [];
    public viewData: any = null;
    public customer?: Customer | null;
    public isGuestManager = false;
    public invoice?: any | null;
    public orderTags?: any[];
    public request?: Request;
    public orderItems?: OrderItem[];
    public isPersonalisationRequired?: boolean;
    public personalisationUrl?: string;
    public directCheckoutLink?: string;
    public orderDeliveries: OrderDelivery[] = [];

    private _expireAt: string;

    isSelected: boolean;

    public orderItemGroups: {
        id: string,
        name: string,
        date: string,
        orderItems: OrderItem[]
    }[] = [];

    constructor() {
        super();
        super.setRoutes({
            getMany: 'v1.0/order'
        });
    }

    getOrderTags(): string[] {
        return this.orderTags?.map(tag => tag.id);
    }

    getPrice() {
        return this.totalPrice;
    }

    hasTag(orderTagId: string): boolean {
        return this.orderTags.findIndex(orderTag => orderTag.id === orderTagId) > -1;
    }

    set expireAt(expireAt: string) {
        this._expireAt = expireAt;

        this.viewData = {
            expireAtUnix: moment(this.expireAt).format('x'),
            expireDifference: moment.duration(moment(this.expireAt).diff(moment())).asMinutes(),
            expireDifferenceString: moment(this.expireAt).fromNow()
        };
    }

    get expireAt(): string {
        return this._expireAt;
    }

    mapModel(data: any) {
        const model = new Order();

        model.id = data.id;
        model.importId = data.importId;
        model.campaignId = data.campaignId;
        model.externalReference = data.externalReference;
        model.batchId = data.batchId;
        model.status = data.status;
        model.customerId = data.customerId;
        model.expireAt = data.expireAt;
        model.payedAt = data.payedAt;
        model.canceledAt = data.canceledAt;
        model.createdAt = data.createdAt;
        model.updatedAt = data.updatedAt;
        model.totalPrice = data.totalPrice;
        model.totalDiscountAmount = data.totalDiscountAmount;
        model.discounts = data.discounts;
        model.orderItemAmount = data.orderItemAmount;
        model.balance = data.balance;
        model.payments = data.payments;
        model.consents = data.consents;
        model.groupedOrderItems = data.groupedOrderItems ? data.groupedOrderItems : [];
        model.additionalCosts = data.additionalCosts ? data.additionalCosts.map(additionalCost => mapAdditionalCost(additionalCost)) : [];
        model.externalData = data.externalData ? data.externalData : [];
        model.customer = data.customer ? mapCustomer(data.customer) : null;
        model.isGuestManager = data.isGuestManager;
        model.invoice = data.invoice;
        model.orderTags = data.orderTags ? data.orderTags : [];
        model.request = data.request ? (new Request()).mapModel(data.request) : null;
        model.orderItems = data.orderItems ? data.orderItems.map(orderItem => mapOrderItem(orderItem)) : [];
        model.campaign = data.campaign ? mapCampaign(data.campaign) : null;
        model.isPersonalisationRequired = data.isPersonalisationRequired;
        model.personalisationUrl = data.personalisationUrl;
        model.directCheckoutLink = data.directCheckoutLink;
        model.orderDeliveries = data.orderDeliveries ? data.orderDeliveries.map(orderDelivery => mapOrderDelivery(orderDelivery)) : [];

        return model;
    }

    public getOrderItemsGrouped(): {
        id: string,
        name: string,
        date: string | undefined,
        type: ProductGroupType,
        types: TicketType[],
        venueId: string,
        orderItems: OrderItem[]
    }[] {
        if (!this.orderItems?.length) {
            return [];
        }

        this.orderItems.forEach((orderItem: OrderItem) => orderItem.initialize());

        const groups: {
            id: string,
            name: string,
            date: string | undefined,
            type: ProductGroupType,
            types: TicketType[],
            venueId: string,
            orderItems: OrderItem[]
        }[] = [];

        this.orderItems.forEach(orderItem => {
            let groupIndex = groups.findIndex(group => group.id === orderItem.groupId);

            if (groupIndex === -1) {
                groups.push({
                    id: orderItem.groupId,
                    name: orderItem.groupName,
                    date: orderItem.groupDate,
                    venueId: orderItem.venueId,
                    types: orderItem.types.map(type => mapTicketType(type)),
                    type: ProductGroupType[orderItem.type],
                    orderItems: []
                });

                groupIndex = groups.length - 1;
            }

            groups[groupIndex].orderItems.push(orderItem);
        });

        groups.forEach(group => {
            group.orderItems.sort((a, b) => {
                if (!a.venueSectionSeat || !b.venueSectionSeat) {
                    return;
                }

                const aValue = `${a.venueSectionSeat.row}.${a.venueSectionSeat.seat}`;
                const bValue = `${b.venueSectionSeat.row}.${b.venueSectionSeat.seat}`;

                return aValue.localeCompare(bValue);
            });
        });

        groups.sort((a, b) => {
            if (!a.type || !b.type) {
                return;
            }

            return a.type.localeCompare(b.type);
        });

        return groups;
    }
}

export function mapOrder(item: any): Order {
    return (new Order()).mapModel(item);
}
