import {CustomField, mapCustomField} from './custom-field';

export class CustomFieldAnswer {
    constructor(public customerId: string = null,
                public customFieldId: string = null,
                public orderId: string = null,
                public requestId: string = null,
                public customFieldOptionIds: string[] = [],
                public value: string = null,
                public customField: CustomField = null) {
    }

}

export function mapCustomFieldAnswer(item: any): CustomFieldAnswer {
    return new CustomFieldAnswer(
        item.customerId,
        item.customFieldId,
        item.orderId,
        item.requestId,
        item.customFieldOptionIds ?? [],
        item.value,
        item.customField ? mapCustomField(item.customField) : null
    );
}
