import {mapResource, Resource} from './event-resource.class';
import {ConsentSetting, mapConsentSetting} from './consent-setting';

export class Consent {

    _resourceType = 'FILE';

    constructor(public id: string,
                public name: string,
                public isRequired: boolean,
                public isAccepted: boolean,
                public resource: Resource,
                public consentSetting: ConsentSetting,
                public nameTranslation: any,
                public createdAt: string = null) {
        if (this.resource) {
            if (this.resource.type === 'WEBSITE') {
                this._resourceType = 'WEBSITE';
            } else {
                this._resourceType = 'FILE';
            }
        }
    }
}

export function mapConsent(item: any): Consent {
    return new Consent(
        item.id,
        item.name,
        item.isRequired,
        item.isAccepted,
        item.resource ? mapResource(item.resource) : mapResource({
            uuid: null,
            type: 'WEBSITE',
            link: null,
            name: null
        }),
        item.consentSetting ? mapConsentSetting(item.consentSetting) : mapConsentSetting([]),
        item.nameTranslation ? item.nameTranslation : null,
        item.createdAt ? item.createdAt : null
    );
}
