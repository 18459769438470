import {mapPriceItem, PriceItem} from './price-item.class';
import {ExternalData, mapExternalData} from './external-data.class';
import {DownloadSetting} from './download-setting.class';
import {mapTranslation, Translation} from './translation';

export class TicketType {

    _isDownloadEnabled: boolean;
    public id: string = null;
    public resellTicketTypeId: string = null;
    public name: string = null;
    public nameTranslation: Translation = null;
    public eventTicketName: string = null;
    public eventTicketNameTranslation: Translation = null;
    public subtitle: string = null;
    public subtitleTranslation: Translation = null;
    public description: string = null;
    public descriptionTranslation: Translation = null;
    public order: number = null;
    public minAge: number = null;
    public maxAge: number = null;
    public isPersonalisationRequired = false;
    public isOnlyResellCapacity = false;
    public prices: PriceItem[] = [];
    public externalData: ExternalData[] = [];
    public downloadSetting: DownloadSetting = null;
    public isResellEnabled: boolean;
    public eventId?: string;
    public c3vEnabled?: boolean;
    public secondary?: boolean;
    public bookingFeeAmount?: number;

    mapModel(data: any): TicketType {
        const model = new TicketType();
        model.id = data.id;
        model.resellTicketTypeId = data.resellTicketTypeId;
        model.name = data.name;
        model.nameTranslation = data.nameTranslation ? mapTranslation(data.nameTranslation) : Translation.empty();
        model.eventTicketName = data.eventTicketName;
        model.eventTicketNameTranslation = data.eventTicketNameTranslation ? mapTranslation(data.eventTicketNameTranslation) : Translation.empty();
        model.subtitle = data.subtitle;
        model.subtitleTranslation = data.subtitleTranslation ? mapTranslation(data.subtitleTranslation) : Translation.empty();
        model.description = data.description;
        model.descriptionTranslation = data.descriptionTranslation ? mapTranslation(data.descriptionTranslation) : Translation.empty();
        model.order = data.order;
        model.minAge = data.minAge;
        model.maxAge = data.maxAge;
        model.isPersonalisationRequired = data.isPersonalisationRequired;
        model.isOnlyResellCapacity = data.isOnlyResellCapacity;
        model.prices = data.prices ? data.prices.map(price => mapPriceItem(price)) : [];
        model.externalData = data.externalData ? data.externalData.map(externalData => mapExternalData(externalData)) : [];
        model.downloadSetting = data.downloadSetting ? new DownloadSetting().mapModel(data.downloadSetting) : null;
        model.isResellEnabled = data.isResellEnabled;
        model.eventId = data.eventId;
        model.c3vEnabled = data.c3vEnabled;
        model.secondary = data.secondary;
        model.bookingFeeAmount = data.bookingFeeAmount ?? 0;
        return model;
    }
}

export function mapTicketType(item: any): TicketType {
    return new TicketType().mapModel(item);
}
