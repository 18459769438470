export class ActivityLog {
    valueKeys;

    constructor(public id: string,
                public event: string,
                public actor: Actor,
                public customerId: string,
                public orderId: string,
                public eventTicketId: string,
                public subscriptionId: string,
                public previousValue: any,
                public value: any,
                public createdAt: string,
                public viewData = []) {
        this.generateValueKeys();
    }

    generateValueKeys() {
        if (this.value) {
            this.valueKeys = Object.keys(this.value);
        }

        return null;
    }
}

export class Actor {
    constructor(public type: string = null,
                public name: string = null,
                public avatarUrl: string = null) {
    }
}

export function mapActivityLog(item: any): ActivityLog {
    return new ActivityLog(
        item.id,
        item.event,
        item.actor,
        item.customerId,
        item.orderId,
        item.eventTicketId,
        item.subscriptionId,
        item.previousValue,
        item.value,
        item.createdAt
    );
}
