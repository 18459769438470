import {VerificationStatus} from '../enums/verification/verification-status';

export class CustomerVerification {
    constructor(public status: VerificationStatus,
                public verificationType: string,
                public verifiedAt: string) {
    }
}

export function mapCustomerVerification(item: any): CustomerVerification {
    return new CustomerVerification(
        item.status,
        item.verificationType,
        item.verifiedAt
    );
}
