import {mapVenueSectionSeat, VenueSectionSeat} from './venue-section-seat.class';
import {VenueSectionGroup} from './venue-section-group.class';
import {ModelBase} from './abstract/model-base';

export class VenueSection extends ModelBase {
    public id: string = null;
    public name: string = null;
    public venueSectionId: string = null;
    public venueSectionGroupId: string = null;
    public entrance: string = null;
    public seats: VenueSectionSeat[] = [];
    public available: number = null;
    public sections: VenueSection[] = [];
    public shopCapacityDisplay = 'SHOP_SETTING';
    public shopCapacityDisplayValue: number = null;
    public isLocked = false;
    public color: string = null;
    public subtitle: string = null;
    public subtitleTranslation = null;
    public venueSectionGroup?: VenueSectionGroup;
    public venueSectionSeatBlockadeAmount?: number;
    public totalCapacity?: number;

    isSeated: boolean;
    isHidden: boolean;

    _isSelected: boolean;
    public _capacity?: number;

    constructor() {
        super();
        super.setRoutes({
            get: 'v1.0/venue/:venueId/section/:venueSectionId',
            getMany: 'v1.0/venue/:venueId/section'
        });
    }

    mapModel(data: any): VenueSection {
        const model = new VenueSection();

        model.id = data.id;
        model.name = data.name;
        model.venueSectionId = data.venueSectionId;
        model.venueSectionGroupId = data.venueSectionGroupId;
        model.entrance = data.entrance;
        model.seats = data.seats ? data.seats.map(seat => mapVenueSectionSeat(seat)) : [];
        model.available = data.available;
        model.sections = data.sections ? data.sections.map(section => (new VenueSection()).mapModel(section)) : [];
        model.shopCapacityDisplay = data.shopCapacityDisplay;
        model.shopCapacityDisplayValue = data.shopCapacityDisplayValue;
        model.isLocked = data.isLocked;
        model.color = data.color;
        model.subtitle = data.subtitle;
        model.subtitleTranslation = data.subtitleTranslation;
        model.venueSectionGroup = data.venueSectionGroup;
        model.venueSectionSeatBlockadeAmount = data.venueSectionSeatBlockadeAmount || 0;
        model.totalCapacity = data.totalCapacity || 0;

        if (data.hasOwnProperty('isSeated')) {
            model.isSeated = data.isSeated;
        } else {
            model.isSeated = model.seats?.length !== 1;
        }


        return model;
    }
}

export function mapVenueSection(item: any): VenueSection {
    const venueSectionSeats: VenueSectionSeat[] = [];

    if (item.seats) {
        for (const seat of item.seats) {
            venueSectionSeats.push(mapVenueSectionSeat(seat));
        }
    }

    return (new VenueSection()).mapModel(item);
}
