export class PriceItem {
    constructor(public id: string                      = null,
                public pricePlanId: string             = null,
                public venueSectionGroupId: string     = null,
                public venueSectionId: string          = null,
                public venueSectionSeatId: string      = null,
                public ticketTypeId: string            = null,
                public subscriptionTypePriceId: string = null,
                public price: number                   = null,
                public vat: number                     = null) {
    }
}

export function mapPriceItem(item: any): PriceItem {
    return new PriceItem(
        item.id,
        item.pricePlanId,
        item.venueSectionGroupId,
        item.venueSectionId,
        item.venueSectionSeatId,
        item.ticketTypeId,
        item.subscriptionTypePriceId,
        item.price,
        item.vat
    );
}

