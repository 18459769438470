import {ModelBase} from './abstract/model-base';

export class UpsellSetting extends ModelBase {
    id: string;
    productTypeId: string;
    filterCustomerTagIds: string[]           = [];
    filterEventCategoryIds: string[]         = [];
    filterEventIds: string[]                 = [];
    filterShopIds: string[]                  = [];
    filterSubscriptionTypeIds: string[]      = [];
    filterSubscriptionTypePriceIds: string[] = [];
    filterTicketTypeIds: string[]            = [];
    filterVenueSectionIds: string[]          = [];
    isAutoAdd: boolean;
    isRequired: boolean;
    maxAmount: number;
    minAmount: number;

    constructor() {
        super();
        super.setRoutes({
                            patch: 'v1.0/product/type/:productTypeId/upsellsetting/:upsellSettingId'
                        });
    }

    mapModel(data: any): UpsellSetting {
        const model                          = new UpsellSetting();
        model.id                             = data.id;
        model.productTypeId                  = data.productTypeId;
        model.filterCustomerTagIds           = data.filterCustomerTagIds;
        model.filterEventCategoryIds         = data.filterEventCategoryIds;
        model.filterEventIds                 = data.filterEventIds;
        model.filterShopIds                  = data.filterShopIds;
        model.filterSubscriptionTypeIds      = data.filterSubscriptionTypeIds;
        model.filterSubscriptionTypePriceIds = data.filterSubscriptionTypePriceIds;
        model.filterTicketTypeIds            = data.filterTicketTypeIds;
        model.filterVenueSectionIds          = data.filterVenueSectionIds;
        model.isAutoAdd                      = data.isAutoAdd;
        model.isRequired                     = data.isRequired;
        model.maxAmount                      = data.maxAmount;
        model.minAmount                      = data.minAmount;

        return model;
    }

    reset() {
        //
    }

}
