export class ExternalData {
    constructor(public key: string = null,
                public value: string = null) {
    }
}

export function mapExternalData(item: any): ExternalData {
    return new ExternalData(
        item.key,
        item.value
    );
}
