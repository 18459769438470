export class VenueSectionSeatSetting {

    constructor(public id: string                 = null,
                public venueSectionSeatId: string = null,
                public eventId: string            = null,
                public eventCategoryId: string    = null,
                public capacity: number           = null) {
    }
}

export function mapVenueSectionSeatSetting(item: any): VenueSectionSeatSetting {
    return new VenueSectionSeatSetting(
        item.id,
        item.venueSectionSeatId,
        item.eventId,
        item.eventCategoryId,
        item.capacity
    );
}
