export class ConsentSetting {
    constructor(
        public filterSubscriptionTypeIds: string[] = [],
        public filterEventIds: string[] = []
    ) {
    }
}

export function mapConsentSetting(item) {
    return new ConsentSetting(
        item.filterSubscriptionTypeIds,
        item.filterEventIds
    );
}
