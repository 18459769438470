import {VenueSection} from './venue-section.class';
import {ModelBase} from './abstract/model-base';
import { Resource } from './event-resource.class';
import { mapTranslation, Translation } from './translation';

export class Venue extends ModelBase {
    public id: string = null;
    public map: boolean = null;
    public name: string = null;
    public nameTranslation: Translation;
    public street: string = null;
    public houseNumber: string = null;
    public postalCode: string = null;
    public city: string = null;
    public province: string = null;
    public country: string = null;
    public latitude: string = null;
    public longitude: string = null;
    public sections: VenueSection[] = [];
    public stageType: string = null;
    public resource: Resource;
    public venueSectionMap: any[];

    constructor() {
        super();
        super.setRoutes({
            get: 'v1.0/venue/:venueId',
            getMany: 'v1.0/venue',
            post: 'v1.0/venue'
        });
    }

    mapModel(data: any): Venue {
        const model = new Venue();

        model.id = data.id;
        model.map = data.map;
        model.name = data.name;
        model.nameTranslation =  data.nameTranslation ? mapTranslation(data.nameTranslation) : Translation.empty();
        model.street = data.street;
        model.houseNumber = data.houseNumber;
        model.postalCode = data.postalCode;
        model.city = data.city;
        model.province = data.province;
        model.country = data.country;
        model.latitude = data.latitude;
        model.longitude = data.longitude;
        model.sections = data.sections ? data.sections.map(section => (new VenueSection()).mapModel(section)) : [];
        model.stageType = data.stageType;
        model.resource = data.resource;
        model.venueSectionMap = data.venueSectionMap;

        return model;
    }

    public getFormattedAddress(): string {
        let address = '';

        if (this.street?.trim()) {
            address += this.street;
        }

        if (this.houseNumber?.trim()) {
            address += address ? ` ${this.houseNumber}` : this.houseNumber;
        }

        if (this.city?.trim()) {
            address += address ? `, ${this.city}` : this.city;
        }

        return address;
    }

}

export function mapVenue(item: any): Venue {
    return (new Venue()).mapModel(item);
}
