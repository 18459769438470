import {ModelBase} from './abstract/model-base';
import {mapTicketType, TicketType} from './ticket-type.class';
import {mapSubscriptionTypePrice, SubscriptionTypePrice} from './subscription-type-price.class';
import {mapVenueSection, VenueSection} from './venue-section.class';
import {Event, mapEvent} from './event.class';
import {mapSubscriptionType, SubscriptionType} from './subscription-type.class';

export class RequestItem extends ModelBase {
    public id: string = null;
    public requestId: string = null;
    public ticketTypeId: string = null;
    public subscriptionTypePriceId: number = null;
    public venueSectionId: string = null;
    public status: string = null;

    public event: Event = null;
    public ticketType: TicketType = null;
    public subscriptionType: SubscriptionType = null;
    public subscriptionTypePrice: SubscriptionTypePrice = null;
    public venueSection: VenueSection = null;

    public _typeId: string;

    constructor() {
        super();
        super.setRoutes({
            getMany: 'v1.0/request/:requestId/item',
            get: 'v1.0/request/:requestId/item/:id',
            patch: 'v1.0/request/:requestId/item/:id'
        });
    }

    mapModel(data: any) {
        const requestItem = new RequestItem();
        requestItem.id = data.id;
        requestItem.requestId = data.requestId;
        requestItem.ticketTypeId = data.ticketTypeId;
        requestItem.subscriptionTypePrice = data.subscriptionTypePrice;
        requestItem.venueSectionId = data.venueSectionId;
        requestItem.status = data.status;

        requestItem.event = data.event ? mapEvent(data.event) : null;
        requestItem.ticketType = data.ticketType ? mapTicketType(data.ticketType) : null;
        requestItem.subscriptionType = data.subscriptionType ? mapSubscriptionType(data.subscriptionType) : null;
        requestItem.subscriptionTypePrice = data.subscriptionTypePrice ? mapSubscriptionTypePrice(data.subscriptionTypePrice) : null;
        requestItem.venueSection = data.venueSection ? mapVenueSection(data.venueSection) : null;

        requestItem._typeId = data.ticketTypeId || data.subscriptionTypePriceId || data.productTypePriceId;

        return requestItem;
    }
}
