export class AdditionalCost {
    constructor(public label: string,
                public amount: string) {
    }
}

export function mapAdditionalCost(item: any): AdditionalCost {
    return new AdditionalCost(
        item.label,
        item.amount
    );
}
