import {EventTicket} from './event-ticket.class';
import {VenueSectionSeat} from './venue-section-seat.class';
import {Customer, mapCustomer} from './customer.class';
import {SubscriptionType} from './subscription-type.class';
import {Order} from './order.class';
import moment from 'moment';
import {mapSubscriptionTypePrice, SubscriptionTypePrice} from './subscription-type-price.class';

export class Subscription {
    constructor(public id: string,
                public name: string,
                public status: string,
                public customerId: string,
                public personalisationCustomerId: string,
                public venueSectionSeatId: string,
                public subscriptionTypeId: string,
                public subscriptionTypePriceId: string,
                public orderItemId: string,
                public hash: string,
                public startAt: string,
                public endAt: string,
                public eventTickets: EventTicket[],
                public seat: VenueSectionSeat,
                public venueSectionAccessIds: string[],
                public fixedBarcode: string,
                public viewData: any,
                public note?: string,
                public order?: Order,
                public subscriptionType?: SubscriptionType,
                public customer?: Customer,
                public personalisationCustomer?: Customer,
                public subscriptionTypePrice?: SubscriptionTypePrice,
                public venueId?: string,
                public venueSectionId?: string,
                public venueSectionGroupId?: string,
                public subscriptionTypeName?: string) {
    }

    public hasLimitedValidity(): boolean {
        return (!!this.startAt || !!this.endAt);
    }

    public getLimitedValidityTranslationKey(): string {
        if (this.startAt && this.endAt) {
            return 'Subscription.Validity_Message.Start_End';
        }

        if (this.startAt) {
            return 'Subscription.Validity_Message.Start';
        }

        if (this.endAt) {
            return 'Subscription.Validity_Message.End';
        }

        return '';
    }

}

export function mapSubscription(item: any): Subscription {
    return new Subscription(
        item.id,
        item.name,
        item.status,
        item.customerId,
        item.personalisationCustomerId,
        item.venueSectionSeatId,
        item.subscriptionTypeId,
        item.subscriptionTypePriceId,
        item.orderItemId,
        item.hash,
        item.startAt,
        item.endAt,
        item.eventTickets,
        item.seat,
        item.venueSectionAccessIds,
        item.fixedBarcode,
        {transferForm: false, transferCustomer: null, expired: moment(item.endAt).isBefore()},
        item.note,
        item.order,
        item.subscriptionType,
        item.customer ? mapCustomer(item.customer) : null,
        item.personalisationCustomer ? mapCustomer(item.personalisationCustomer) : null,
        item.subscriptionTypePrice ? mapSubscriptionTypePrice(item.subscriptionTypePrice) : null,
        item.venueId,
        item.venueSectionId,
        item.venueSectionGroupId,
        item.subscriptionTypeName
    );
}
