import {ModelBase} from './abstract/model-base';
import {ProductTypePrice} from './product-type-price';
import {UpsellSetting} from './upsell-setting';

export class ProductType extends ModelBase {
    id: string = null;
    name: string = null;
    nameTranslation: any = null;
    description: string = null;
    descriptionTranslation: any = null;
    productTypePrices: ProductTypePrice[] = [
        new ProductTypePrice()
    ];
    upsellSettings: UpsellSetting[] = [];
    displayOrder: number;

    constructor() {
        super();
        super.setRoutes({
            get: 'v1.0/product/type/:productTypeId',
            getMany: 'v1.0/product/type',
            post: 'v1.0/product/type',
            patch: 'v1.0/product/type/:productTypeId',
            delete: 'v1.0/product/type/:productTypeId'
        });
    }

    mapModel(data: any): ProductType {
        const model = new ProductType();
        model.id = data.id;
        model.name = data.name;
        model.nameTranslation = data.nameTranslation;
        model.description = data.description;
        model.descriptionTranslation = data.descriptionTranslation;
        model.productTypePrices = data.productTypePrices ? data.productTypePrices : [];
        model.upsellSettings = data.upsellSettings.length > 0 ? [new UpsellSetting().mapModel(data.upsellSettings[0])] : [new UpsellSetting()];
        model.displayOrder = data.displayOrder;

        return model;
    }

    reset() {
        this.productTypePrices.forEach(productTypePrice => {
            productTypePrice.hasUnsavedChanges = false;
        });
    }

}
