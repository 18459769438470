import {ModelBase} from './abstract/model-base';
import {Resource} from './event-resource.class';
import {mapPriceItem, PriceItem} from './price-item.class';

export class ProductTypePrice extends ModelBase {
    id: string;
    productTypeId: string;
    name: string;
    nameTranslation: any;
    resource: Resource;
    order: number;
    capacity: number;
    available: number;
    maxAmount: number;
    soldAmount = 0;
    priceItem: PriceItem;

    hasUnsavedChanges: boolean;

    constructor() {
        super();
        super.setRoutes({
            get: 'v1.0/product/type/:productTypeId/price/:productTypePriceId',
            getMany: 'v1.0/product/type/:productTypeId/price',
            post: 'v1.0/product/type/:productTypeId/price',
            patch: 'v1.0/product/type/:productTypeId/price/:productTypePriceId',
            delete: 'v1.0/product/type/:productTypeId/price/:productTypePriceId'
        });

        this.name = 'Untitled';
        this.order = 0;
        this.priceItem = new PriceItem();

        this.hasUnsavedChanges = false;
    }

    mapModel(data: any): ProductTypePrice {
        const model = new ProductTypePrice();
        model.id = data.id;
        model.name = data.name;
        model.nameTranslation = data.nameTranslation;
        model.resource = data.resource;
        model.order = data.order;
        model.capacity = data.capacity;
        model.available = data.available;
        model.maxAmount = data.maxAmount;
        model.soldAmount = data.soldAmount;
        model.priceItem = data.priceItem ? mapPriceItem(data.priceItem) : new PriceItem();

        return model;
    }

}
