import {mapResource, Resource} from './event-resource.class';
import { Consent } from './consent.class';
import { Event } from './event.class';

export class ConsentGrant {

    _resourceType = 'FILE';

    constructor(public id: string,
                public name: string,
                public resource: Resource,
                public eventName: string,
                public subscriptionTypeName: string,
                public consent: Consent = null,
                public event: Event = null,
                public createdAt: string = null) {
        if (this.resource) {
            if (this.resource.type === 'WEBSITE') {
                this._resourceType = 'WEBSITE';
            } else {
                this._resourceType = 'FILE';
            }
        }
    }
}

export function mapConsentGrant(item: any): ConsentGrant {
    return new ConsentGrant(
        item.id,
        item.name,
        item.resource ? mapResource(item.resource) : mapResource({
            uuid: null,
            type: 'WEBSITE',
            link: null,
            name: null
        }),
        item.eventName,
        item.subscriptionTypeName,
        item.consent ? item.consent : null,
        item.event ? item.event : null,
        item.createdAt ? item.createdAt : null
    );
}
