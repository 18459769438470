import {EventTicket, mapEventTicket} from './event-ticket.class';
import {mapVenueSectionSeatSetting, VenueSectionSeatSetting} from './venue-section-seat-setting.class';

export class VenueSectionSeat {

    parentSectionName: string = null;
    _transform: string;
    _textTransform: string;
    _blockadeTransform: string;
    _blockadeTextTransform: string;

    // Seat Map
    public _color: string = null;
    public _label: string = null;
    public _selected = false;
    public _blockadeId: string = null;
    public _originalBlockadeId: string = null;

    public _isProcessing = false;

    constructor(public id: string,
                public width: number,
                public height: number,
                public x: number,
                public y: number,
                public seat: number,
                public row: number,
                public capacity: number,
                public available: number,
                public sectionName: string,
                public status: string,
                public state: string,
                public rowOffset: number,
                public seatOffset: number,
                public rowDisplayNumber: number,
                public seatDisplayNumber: number,
                public details?: any,
                public eventTickets?: EventTicket[],
                public venueSectionId?: string,
                public venueSectionSubsectionId?: string,
                public venueSectionSeatSettings?: VenueSectionSeatSetting[],
                public loading = false) {
    }

    public isAvailable(): boolean {
        return this.details?.status === null;
    }
}

export function mapVenueSectionSeat(item: any): VenueSectionSeat {
    return new VenueSectionSeat(
        item.id,
        item.width,
        item.height,
        item.x,
        item.y,
        item.seat,
        item.row,
        item.capacity,
        item.available,
        item.sectionName,
        item.status,
        item.state,
        item.rowOffset,
        item.seatOffset,
        item.rowDisplayNumber,
        item.seatDisplayNumber,
        item.details,
        item.eventTickets ? item.eventTickets.map(eventTicket => mapEventTicket(eventTicket)) : [],
        item.venueSectionId,
        item.venueSectionSubsectionId,
        item.venueSectionSeatSettings ? item.venueSectionSeatSettings.map(venueSectionSeatSetting => mapVenueSectionSeatSetting(venueSectionSeatSetting)) : null,
        item.loading
    );
}
